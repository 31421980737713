@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&family=Roboto:wght@300;500;900&display=swap);
@import "~normalize.css/normalize.css";

html,
body {
  margin: 0 !important;
  padding: 0;
  background-color: #fefefe;
  color: #222;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: Roboto, sans-serif;
  overflow-x: hidden;
  background-color: #eee;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Fjalla One, sans-serif;
}

.loading-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.smartbanner-top {
  position: relative !important;
}
